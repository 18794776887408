import * as React from "react"
import { Link } from "gatsby"

const Footer = () => (
    <footer
        className="footer"
    >
    <div className="footer-section" >
        <h1 className="footer-title text-glow">
            Clean Apps
        </h1>
        <h5 className="footer-body">
            Clean Apps is your go to place for all of your iPhone application needs.<br></br>
            Work with us to make your app dreams a reality!
        </h5>
        <div className="built ">
        © {new Date().getFullYear()}, Built with
        {` `}
        <a className="built-text-glow" style={{color: 'white'}}href="https://www.gatsbyjs.com">Gatsby</a>
        </div>
        
    </div>
    <div className="footer-section subtitle-text-glow" style={{bottom: '0', alignItems: 'flex-end', marginTop: 'auto'}}>
        <a style={{marginBottom: '10px'}}className="footer-link built-text-glow" href='mailto:joe@blipp.org?subject=Business Inquiry: I want to make an iPhone Application!
            &body=Hi Clean Apps,%0D%0A%0D%0A

            My name is: <Your name here>%0D%0A
            I want to make an iPhone application that: <briefly describe your application>%0D%0A
            I need you to: <Describe a service you are requesting. Examples: Develop an application, design an application, advise me on how to get started>
            %0D%0A
            %0D%0A
            Thanks,'>Contact Us
        </a>
        <Link style={{color: 'white'}} className="footer-link built-text-glow" to="/privacy">
            Privacy Policy
        </Link>
    </div>
  </footer>
  )
  
  export default Footer
  