import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const color = `#0b0414`

const Header = ({ siteTitle }) => (

  <header
    style={{
      background: color,
      marginBottom: `1.45rem`,
    }}
  >
    <nav
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 className="text-glow" style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <h5 className="white-text-glow header-link" style={{
        color:`White`,
        margin: `3px`, 
        marginTop: `10px`
      }}>
        <Link 
            to="/about"
              style={{
              color: `white`,
              textDecoration: `none`,
            }}>
              About  
        </Link>
          <> | </>
          <Link 
            to="/portfolio"
              style={{
              color: `white`,
              textDecoration: `none`,
            }}>
              Portfolio
          </Link>
          <> | </>
          <Link 
            to="/contact"
              style={{
              color: `white`,
              textDecoration: `none`,
            }}>
              Contact
          </Link>
      </h5>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
